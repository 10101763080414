export const backgroundStyles = (image) => {
    const defaultStyles = {
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
    };

    const styleWithImage = {
        'background-image': `url(${image})`,
        ...defaultStyles,
    };

    return image ? styleWithImage : defaultStyles;
};
