<template>
    <div class="blog-card">
        <div class="wrapper" :style="backgroundStyles(image)">
            <h4>{{ title }}</h4>
            <p>{{ date }}</p>
        </div>
        <CRButton v-if="isAdminView" class="close-btn" close @click.stop="deletePost" />
    </div>
</template>
<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { backgroundStyles } from '@/helpers/CssHelper';

    export default {
        name: 'BlogCard',
        components: { CRButton },
        props: {
            id: {
                type: Number,
                default: 0,
            },
            title: {
                type: String,
                default: '',
            },
            image: {
                type: String,
                default: '',
            },
            date: {
                type: String,
                default: '',
            },
            isAdminView: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['deleted'],
        data() {
            return {
                backgroundStyles,
            };
        },
        methods: {
            deletePost() {
                this.$emit('delete', this.id);
            },
        },
    };
</script>
<style lang="scss" scoped>
    .blog-card {
        position: relative;

        width: 100%;
        height: 100%;

        transition: all 0.2s linear;

        cursor: pointer;

        overflow: hidden;

        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .wrapper {
        @include column-align-start-justify-end;
        position: relative;

        padding: 10px 15px;

        width: 100%;
        height: 100%;

        min-height: 287px;

        border-radius: $br-10;

        background-image: url('../../assets/images/backgrounds/empty-mealKit-bg.png');

        overflow: hidden;

        h4,
        p {
            color: $white;
            z-index: $index-100;
            font-weight: 700;
        }

        p {
            font-size: $font-14;

            margin-top: 3px;
        }

        &::after {
            content: '';
            top: 0;
            left: 0;
            background: linear-gradient(
                360deg,
                rgba(0, 0, 0, 0.795) 0%,
                rgba(255, 255, 255, 0) 57%,
                rgba(255, 255, 255, 0) 100%
            );
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }

    @include media($lg) {
        .blog-card:hover {
            transform: scale(1.02);
        }
    }
</style>
