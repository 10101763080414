<template>
    <div class="blog-articles-container">
        <h1 class="title">{{ $t('blogArticles.blogPosts') }}</h1>
        <CRButton class="mt-25" @click="toCreatePage"> {{ $t('buttons.newBlogPost') }} </CRButton>
        <div v-if="!blogArticles.length" class="empty__wrapper">
            <h1>{{ $t('blogArticles.noBlogArticlesAdded') }}</h1>
        </div>
        <div v-else class="card__wrapper">
            <BlogCard
                class="card"
                v-for="blogArticle in blogArticles"
                :key="blogArticle.id"
                :id="blogArticle.id"
                @click="onBlogArticleClick(blogArticle.id)"
                :title="$filters.getTranslationByKey(blogArticle.title)"
                :image="blogArticle.pictures[0]?.picturePath.thumbnail"
                :date="blogArticle.createdAt"
                is-admin-view
                @delete="openDeleteModal"
            />
        </div>
        <Pagination
            v-if="blogArticles.length"
            :current-page="meta.currentPage"
            :page-count="meta.pageCount"
            :total-count="meta.totalCount"
            :per-page="meta.perPage"
            @pageNum="changePage"
        />

        <DeleteConfirmationModal
            v-if="isDeleteModalOpen"
            :title="$t('blogArticles.blogPost')"
            @close="closeDeleteModal"
            @delete="deleteBlogArticle"
        />
    </div>
</template>

<script>
    import DeleteConfirmationModal from '@/components/Modals/DeleteConfirmationModal.vue';
    import BlogCard from '@/components/BlogArticles/BlogCard.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import Pagination from '@/components/Common/Pagination/Pagination.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AllBlogArticles',
        components: {
            BlogCard,
            CRButton,
            Pagination,
            DeleteConfirmationModal,
        },
        data() {
            return {
                isDeleteModalOpen: false,
                deletedBlogId: null,
            };
        },
        computed: {
            ...mapGetters('blogArticle', ['blogArticles', 'meta']),
        },
        methods: {
            ...mapActions('blogArticle', ['getAllBlogArticles', 'destroyBlogArticle']),

            openDeleteModal(id) {
                this.isDeleteModalOpen = true;
                this.deletedBlogId = id;
            },

            closeDeleteModal() {
                this.isDeleteModalOpen = false;
                this.deletedBlogId = null;
            },

            async deleteBlogArticle() {
                try {
                    await this.destroyBlogArticle(this.deletedBlogId);
                    this.closeDeleteModal();
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            onBlogArticleClick(id) {
                this.$router.push({
                    name: 'updateBlogArticle',
                    params: { blog_article_id: id },
                });
            },

            toCreatePage() {
                this.$router.push({ name: 'createBlogArticle' });
            },

            async changePage(pageNum) {
                try {
                    await this.getAllBlogArticles({ page: pageNum });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            try {
                await this.getAllBlogArticles();
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .blog-articles-container {
        padding-bottom: 30px;

        .title {
            display: none;
        }

        .filter-section {
            margin: 20px 0;
        }

        .empty__wrapper {
            @include column-align-center-justify-center;

            min-height: 300px;
            width: 100%;
        }

        .card__wrapper {
            margin-top: 14px;

            .card {
                margin-bottom: 25px;

                &:hover {
                    transform: scale(1);
                }
            }
        }
    }

    @include media($md) {
        .blog-articles-container {
            padding-bottom: 60px;

            .card__wrapper {
                @include row-align-start;

                flex-wrap: wrap;

                margin-bottom: 40px;

                .card {
                    width: 49%;

                    margin-right: 2%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @include media($lg) {
        .blog-articles-container {
            .title {
                display: block;
            }
        }
    }

    @include media($xl) {
        .blog-articles-container {
            .card__wrapper {
                margin-top: 20px;

                .card {
                    width: 32%;
                }

                .card {
                    width: 32%;

                    &:nth-of-type(2n) {
                        margin-right: 2%;
                    }

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
