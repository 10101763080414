<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2>{{ $t('common.areYouSureDelete') }} {{ title }}?</h2>
            <div class="btn__wrapper">
                <CRButton class="btn" @click="$emit('close')">
                    {{ $t('buttons.cancel') }}
                </CRButton>
                <CRButton class="btn" color="danger" @click="deleteItem">
                    {{ $t('buttons.delete') }}
                </CRButton>
            </div>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';

    export default {
        name: 'DeleteConfirmationModal',
        components: { BasicModal, CRButton },
        props: {
            title: {
                type: String,
                default: '',
            },
        },
        emits: ['close', 'delete'],
        methods: {
            deleteItem() {
                this.$emit('delete');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-start;

        width: 100%;

        padding: 48px;

        h2 {
            max-width: 338px;

            color: $primary;
        }

        .btn__wrapper {
            @include row-align-start;

            margin-top: 18px;

            .btn:first-child {
                margin-right: 8px;
            }
        }
    }
</style>
